import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { NavigationBar } from "./NavigationBar";

export const PageLayout = (props) => {
    
    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <NavigationBar />
            <br />
            <h5><center>Welcome to the Account System sample React Web app with an API backend</center></h5>
            <br />
            {props.children}
            <br />
            <AuthenticatedTemplate>
                <footer>
                    <center>Code Repo: 
                        <a href="" target="_blank"> Account System Sample App</a>
                    </center>
                </footer>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h6> <center> Please sign-in to see your profile information.</center></h6>
            </UnauthenticatedTemplate>
        </>
    );
};