import { useEffect, useState } from "react";

import {  useMsal, useAccount } from "@azure/msal-react";

import {  publicResources } from "../authConfig";
import { callApi } from "../fetch";
import { LocationPublicData } from "../components/DataDisplay";

const LocationPublicContent = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance, 
     * an array of all accounts currently signed in and an inProgress value 
     * that tells you what msal is currently doing. For more, visit: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [locationPublicData, setLocationPublicData] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none" && !locationPublicData) {
                callApi(publicResources.locationAPI.endpoint)
                    .then(response => setLocationPublicData(response))
                    .catch(error => console.log(error));
           
        }
    }, [account, inProgress, instance]);
  
    return (
        <>
            { locationPublicData ? <LocationPublicData locationPublicData={locationPublicData} /> : null }
        </>
    );
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const LocationPublic = () => {


    return (
       
            <LocationPublicContent />
      )
};