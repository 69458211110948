import { useEffect, useState } from "react";

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType} from "@azure/msal-browser";

import { loginRequest, protectedResources } from "../authConfig";
import { callApiWithToken } from "../fetch";
import {LocationPrivateData, CapitalsPrivateData, PopulationPrivateData} from "../components/DataDisplay";

const LocationPrivateContent = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance, 
     * an array of all accounts currently signed in and an inProgress value 
     * that tells you what msal is currently doing. For more, visit: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [locationPrivateData, setLocationPrivateData] = useState(null);
    const [capitalsPrivateData, setCapitalsPrivateData] = useState(null);
    const [populationPrivateData, setPopulationPrivateData] = useState(null);
    

    useEffect(() => {
        let url = `${protectedResources.locationAPI.baseUrl}${protectedResources.locationAPI.countriesEndpoint}`;
        //Countries endpoint
        if (account && inProgress === "none" && !locationPrivateData) {
            instance.acquireTokenSilent({
                scopes: protectedResources.locationAPI.scopes,
                account: account
            }).then((response) => {
                callApiWithToken(response.accessToken, url)
                    .then(response => setLocationPrivateData(response.countries));
            }).catch((error) => {
                    // in case if silent token acquisition fails, fallback to an interactive method
                    if (error instanceof InteractionRequiredAuthError) {
                        if (account && inProgress === "none") {
                            instance.acquireTokenPopup({
                                scopes: protectedResources.locationAPI.scopes,
                            }).then((response) => {
                                callApiWithToken(response.accessToken, url)
                                    .then(response => setLocationPrivateData(response.countries));
                            }).catch(error => console.log(error));
                        }
                    }
                });
        }
        //Capitals endpoint
        if (account && inProgress === "none" && !capitalsPrivateData) {
        instance.acquireTokenSilent({
            scopes: protectedResources.locationAPI.scopes,
            account: account
        }).then((response) => {
            callApiWithToken(response.accessToken, `${protectedResources.locationAPI.baseUrl}${protectedResources.locationAPI.capitalsEndpoint}`)
                .then(response => setCapitalsPrivateData(response.capitals))
        }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({}).then((response) => {
                            callApiWithToken(response.accessToken, `${protectedResources.locationAPI.baseUrl}${protectedResources.locationAPI.capitalsEndpoint}`)
                                .then(response => setCapitalsPrivateData(response.capitals));
                        }).catch(error => console.log(error));
                    }
                }
            });
    }
        //Population endpoint
        if (account && inProgress === "none" && !populationPrivateData) {
            instance.acquireTokenSilent({
                scopes: protectedResources.locationAPI.scopes,
                account: account
            }).then((response) => {
                callApiWithToken(response.accessToken, `${protectedResources.locationAPI.baseUrl}${protectedResources.locationAPI.populationEndpoint}`)
                    .then(response => setPopulationPrivateData(response.population))
            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({}).then((response) => {
                            callApiWithToken(response.accessToken, `${protectedResources.locationAPI.baseUrl}${protectedResources.locationAPI.populationEndpoint}`)
                                .then(response => setPopulationPrivateData(response.population));
                        }).catch(error => console.log(error));
                    }
                }
            });
        }
        
       
    }, [account, inProgress, instance]);
  
    return (
        <>
            { locationPrivateData ? <LocationPrivateData locationPrivateData={locationPrivateData} /> : null }
            { capitalsPrivateData ? <CapitalsPrivateData capitalsPrivateData={capitalsPrivateData} /> : null }
            { populationPrivateData ? <PopulationPrivateData populationPrivateData={populationPrivateData} /> : null }
            
        </>
    );
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const LocationPrivate = () => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest}
        >
            <LocationPrivateContent />
        </MsalAuthenticationTemplate>
      )
};