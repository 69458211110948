import { useEffect, useState } from "react";

import { useMsal, useAccount } from "@azure/msal-react";
import {BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";

import { ProfileData } from "../components/DataDisplay";

const ProfileContent = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none" && !profileData) {
            instance.acquireTokenSilent({
                account: account
            }).then((response) => {
                let data = {
                    givenName : account.idTokenClaims.given_name,
                    surname:  account.idTokenClaims.family_name,
                    userPrincipalName:  account.idTokenClaims.email,
                    id:  account.localAccountId,
                };
                setProfileData(data)
            }).catch((error) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                if (error instanceof InteractionRequiredAuthError ) {
                    if (account && inProgress === "none") {
                        instance.acquireTokenPopup({
                            account: account
                        }).then((response) => {
                            let data = {
                                givenName : accounts[0].idTokenClaims.given_name,
                                surname:  accounts[0].idTokenClaims.family_name,
                                userPrincipalName:  accounts[0].idTokenClaims.email,
                                id:  accounts[0].localAccountId,
                            };
                            setProfileData(data)
                        }).catch(error => console.log(error));
                    }
                }
            });
        }
        //Logging account to the console for troubleshooting
        console.log(`This is account ${JSON.stringify(account, null, 2)}`);
    }, [account, inProgress, instance]);
    
    return (
        <>
            { profileData ? <ProfileData profileData={profileData} /> : null  }
        </>
    );
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const Profile = () => {


    return (

        <ProfileContent />
    )
};