import "../styles/App.css";
import { protectedResources, publicResources } from "../authConfig";

export const LocationPrivateData = (props) => {
    const tableRows = Object.entries(props.locationPrivateData).map((entry, index) => {
        return (<tr key={index}>
            <td><b>{entry[0]}: </b></td>
            <td>{entry[1]}</td>
        </tr>)
    });

    return (
        <>
        <div className="data-area-div">
            <p>Calling <strong>custom web API protected by scope</strong>...</p>
            <ul>
                <li><strong>endpoint:</strong> <mark>{protectedResources.locationAPI.baseUrl}{protectedResources.locationAPI.countriesEndpoint}</mark></li>
                <li><strong>scope:</strong> <mark>{protectedResources.locationAPI.scopes[0]}</mark></li>
            </ul>
            <p>Contents of the <strong>response</strong> is below:</p>
        </div>
        <div className="data-area-div">
            <table>
                <thead>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </div>
        </>
    );
}

export const LocationPublicData = (props) => {
    const tableRows = Object.entries(props.locationPublicData).map((entry, index) => {
        return (<tr key={index}>
            <td><b>{entry}: </b></td>
        </tr>)
    });

    return (
        <>
            <div className="data-area-div">
                <p>Calling <strong>custom public web API </strong>...</p>
                <ul>
                    <li><strong>endpoint:</strong> <mark>{publicResources.locationAPI.endpoint}</mark></li>
                </ul>
                <p>Contents of the <strong>response</strong> is below:</p>
            </div>
            <div className="data-area-div">
                <table>
                    <thead>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export const CapitalsPrivateData = (props) => {
    const tableRows = Object.entries(props.capitalsPrivateData).map((entry, index) => {
        return (<tr key={index}>
            <td><b>{entry[0]}: </b></td>
            <td>{entry[1]}</td>
        </tr>)
    });

    return (
        <>
        <div className="data-area-div">
            <br/><br/>
            <p>Calling <strong>custom protected web API </strong>...</p>
            <ul>
                <li><strong>endpoint:</strong> <mark>{protectedResources.locationAPI.baseUrl}{protectedResources.locationAPI.capitalsEndpoint}</mark></li>
                <li><strong>scope:</strong> <mark>{protectedResources.locationAPI.scopes[0]}</mark></li>
            </ul>
            <p>Contents of the <strong>response</strong> is below:</p>
        </div>
        <div className="data-area-div">
            <table>
                <thead>
                </thead>
                <tbody>
                    {tableRows}
                </tbody>
            </table>
        </div>
        </>
    );
}

export const PopulationPrivateData = (props) => {
    const tableRows = Object.entries(props.populationPrivateData).map((entry, index) => {
        return (<tr key={index}>
            <td><b>{entry[0]}: </b></td>
            <td>{entry[1]}</td>
        </tr>)
    });

    return (
        <>
            <div className="data-area-div">
                <br/><br/>
                <p>Calling <strong>claims protected endpoint</strong>...</p>
                <ul>
                    <li><strong>endpoint:</strong> <mark>{protectedResources.locationAPI.baseUrl}{protectedResources.locationAPI.populationEndpoint}</mark></li>
                    <li><strong>scope:</strong> <mark>{protectedResources.locationAPI.scopes[0]}</mark></li>
                    <li><strong>claim:</strong> <mark>name</mark></li>
                </ul>
                <p>Contents of the <strong>response</strong> is below:</p>
            </div>
            <div className="data-area-div">
                <table>
                    <thead>
                    </thead>
                    <tbody>
                    {tableRows}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export const ProfileData = (props) => {

    return (
        <>
            <div className="data-area-div">
                <table>
                    <thead>
                    </thead>
                    <tbody>
                    <tr><td><b>ID:  </b></td><td>{props.profileData.id}</td></tr>
                    <tr><td><b>First Name:  </b></td><td>{props.profileData.givenName}</td></tr>
                    <tr><td><b>Last Name:  </b></td><td>{props.profileData.surname}</td></tr>
                    <tr><td><b>Email:  </b></td><td>{props.profileData.userPrincipalName}</td></tr>
                    
                    </tbody>
                </table>
            </div>
        </>
    );
}